import * as yup from "yup";

export const RegisterFormValidationSchema = yup.object().shape({
    store_name: yup.string().required("Nome da loja é um campo obrigatório"),
    subdomain: yup.string().required(),
    store_category_id: yup.string().required("Categoria é um campo obrigatório"),
    first_name: yup.string().required("Nome é um campo obrigatório"),
    last_name: yup.string().required("Sobrenome é um campo obrigatório"),
    phone: yup.string().required("Número de telefone é um campo obrigatório").min(10),
    email: yup.string().required("E-mail é um campo obrigatório").email(),
    password: yup
        .string()
        .required("Senha é um campo obrigatório")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%#*?&]/,
            "Deve conter 8 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial"
        ),
    confirm_password: yup
        .string()
        .required("Confirmar senha é um campo obrigatório")
        .oneOf([yup.ref("password")], "As senhas devem coincidir"),
});
