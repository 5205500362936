import { Link } from "react-router-dom";
import { PrimaryButton } from "../../../Shared/Buttons/Buttons";

const HeroSection = () => {
	return (
		<section className="flex flex-col py-10 gap-4 justify-center text-center items-center">
			<div className="md:text-3xl text-xl font-bold flex flex-col gap-4">
				<div className="md:text-2xl text-lg">
					<span>Igaming Soft</span>
				</div>
				<div className="bg-gradient-btn text-transparent bg-clip-text animate-gradientText">
					Crie e costumize seu site de apostas
				</div>
			</div>
			<div className="flex flex-col gap-4">
				<div className="md:w-[530px] text-gray-600 text-sm">
					<span>
						Com o Igaming Soft você pode criar seu site de apostas de forma
						simples e rápida. Com uma interface amigável e intuitiva, você pode
						costumizar seu site de acordo com suas necessidades.
					</span>
				</div>
				<div className="flex justify-center">
					<Link to="/register">
						<PrimaryButton className={`rounded-full`}>
							Criar meu casino
						</PrimaryButton>
					</Link>
				</div>
			</div>
		</section>
	);
};

export default HeroSection;
