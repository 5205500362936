import Login from "../pages/LandingPage/Login";

const AuthRoutes = [
	{
		id: 1,
		path: "/login",
		component: <Login />,
	},
];

export default AuthRoutes;
