export const initialValues = {
	theme: "Default",
	logo: "",
	favicon: "",
	selected_hero: "Default",
	hero_title: "",
	hero_subtitle: "",
	hero_button_text: "",
	hero_button_url: "",
	hero_image: "",
	selected_navbar: "Default",
	topbar_text: "",
	topbar_url: "",
	ad1_image: "",
	ad1_url: "",
	ad2_image: "",
	ad2_url: "",
	youtube_video: "",
};
